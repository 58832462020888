import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { BsInstagram, BsEnvelopeFill, BsGithub } from "react-icons/bs";

const info = {
    contactEmail: "contato@lisbom.com.br",
    companyDesc: "Uma empresa dedicada a você e ao seu projeto, focada 101% na solução que está aí no seu papel.",
}

const Footer = () => {
    return (
        <footer className='w-full bg-lisbomDark text-lisbomGray pt-5 lg:px-40 px-10'>
            <div className='md:flex lg:justify-between text-center'>
                <span className='my-8 text-3xl font-bold md:text-left sm:text-center'>
                    <StaticImage
                        className='-mt-1 mx-2'
                        src="../images/Ganso.svg"
                        alt="Lisbom-Icon"
                        placeholder="blurred"
                        quality={100}
                    />
                    <span>
                        <p className='mt-5 md:mb-0 mb-12 text-base font-normal lg:w-4/6 md:w-full'>{info.companyDesc}</p>
                    </span>
                </span>
                <span className='my-8 text-3xl font-bold md:text-right sm:text-center'>
                    Contato
                    <span>
                        <p className='mt-2 text-base font-normal lg:w-4/6 md:w-full hover:text-white transition'>
                            <a href="mailto:contato@lisbom.com.br">{info.contactEmail}</a>
                        </p>
                        <div className="items-center flex mt-2 justify-center">
                            <a href='http://instagram.com/lisbom.ofc' className="md:ml-5 w-6 text-gray-500 hover:text-white transition">
                                <BsInstagram size={22} />
                            </a>
                            <a href='mailto:contato@lisbom.com.br' className="ml-5 w-6 text-gray-500 hover:text-white transition">
                                <BsEnvelopeFill size={25} />
                            </a>
                            <a href='https://github.com/lisbom-dev' className="ml-5 w-6 text-gray-500 hover:text-white transition">
                                <BsGithub size={24} />
                            </a>
                        </div>
                    </span>
                </span>
            </div>
            <div className='w-full my-3 px-10 lg:px-40 border-lisbomGray border border-solid'></div>
            <div className='pt-2 pb-3 flex justify-center text-center'>© 2022 Copyright, Lisbom. All rights reserved.</div>
        </footer>
    );
}
export default Footer;