import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const projects = [
  {
    id: "spacie",
    title: "Spacie",
    content:
      "Aplicativo WEB focado na contratação de influencers e conexão de empresa-influencer que atende, com uma base mais sólida no mercado brasileiro, com tecnologia Lisbom.",
    tags: ["Mobile", "Web"],
  },
  {
    id: "lisbom",
    title: "Lisbom",
    content:
      "Aplicativo de música inovador e autoral, feito totalmente na Web 3.0 com a proposta de aumenta ainda mais a conexão entre banda, cantores à seus fãs por meio de streaming.",
    tags: ["Mobile", "Web"],
  },
  {
    id: "thehubgram",
    title: "Thehubgram",
    content:
      "Um sistema para gerenciamento de salas vips no telegram, whatsapp e discord, que permite automatizar acesso de usuários, gerenciamento de pagamentos e muito mais.",
    tags: ["Web"],
  },
  {
    id: "expert-stats",
    title: "Expert Stats",
    content:
      "Aplicativo WEB focado em estatísticas de jogos de futebol, com uma base mais sólida no mercado brasileiro, com tecnologia Lisbom.",
    tags: ["Web", "Mobile"],
  },
];

const OurProjects = () => {
  return (
    <section>
      <StaticImage
        className="md:-mb-2 !hidden sm:!hidden lg:!block select-none"
        src="../images/projectsTop.svg"
        alt="top curved png"
        placeholder="blurred"
        quality={100}
      />
      <div className="w-full items-center px-10 py-8 md:my-0 sm:my-6 xl:px-48 bg-lisbomDark border-none">
        <h1 className="font-bold text-white  text-center text-3xl md:text-5xl lg:text-6xl">
          Nossos Projetos
        </h1>
        <div className="flex flex-wrap gap-4 justify-center w-full">
          {projects.map((project) => (
            <div
              key={project.id}
              className="bg-lisbomBoxDark text-center rounded-lg px-4 py-5 mt-5 lg:w-1/5"
            >
              <div className="h-[80%]">
                <h2 className="font-bold text-white text-2xl mb-3">
                  {project.title}
                </h2>
                <p className="text-white text-sm">{project.content}</p>
              </div>
              <div className="items-center flex justify-center mt-3 h-[20%]">
                {project.tags.map((tag) => (
                  <span
                    key={tag}
                    className="text-white bg-lisbomProjBox px-3 mx-2 rounded-xl"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <StaticImage
        className="-mt-1 !hidden sm:!hidden lg:!block select-none"
        src="../images/projectsBot.svg"
        alt="bottom curved png"
        placeholder="blurred"
      />
    </section>
  );
};
export default OurProjects;
