import { getDatabase, ref, set, update } from "firebase/database";
import { Field, Form, Formik } from "formik";
import app from "gatsby-plugin-firebase-v9.0";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("O Nome é obrigatório"),
  phone: Yup.string().required("O Telefone é obrigatório"),
  email: Yup.string().required("O Email é obrigatório").email("Email inválido"),
});

const Contact = () => {
  const handleSubmit = async (data) => {
    try {
      const db = getDatabase(app);
      const contactsRef = ref(db, `/contacts/${btoa(data.email)}`);
      await update(contactsRef, set(contactsRef, data));
      Swal.fire("Excelcior!", "Entraremos em contato!", "success");
    } catch (err) {
      Swal.fire("Oops!", "Ocorreu um erro, tente novamente", "error");
      console.log(err);
    }
  };

  return (
    <section
      id="contact"
      className="flex flex-wrap w-full justify-center items-center mb-8 md:mt-10 lg:mb-26 px-5 xl:px-40 mt-16"
    >
      <div className="lg:w-1/2 md:mx-5 mx-1">
        <h1 className="font-bold text-gray-900  text-center text-3xl md:text-5xl lg:text-6xl lg:text-left">
          Contato
        </h1>
        <p className=" text-gray-500 text-center text-base my-6 lg:text-left">
          Fique atualizado sobre nós e acompanhe esse desenvolvimento. Preenche
          seus dados abaixo, eles estão seguros :3
        </p>

        <Formik
          className="my-6"
          initialValues={{
            name: "",
            email: "",
            phone: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form className="gap-3 flex flex-col">
              <div>
                <label
                  htmlFor="name"
                  className="relative my-3 text-gray-400 focus-within:text-gray-600 block"
                >
                  <FaUser className="pointer-events-none w-6 h-6 transition-colors duration-100 absolute top-1/2 transform -translate-y-1/2 left-3" />
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Seu nome completo"
                    className="form-input rounded-md border border-gray-900 py-3 px-4 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-14 focus:outline-none"
                  />
                </label>
                {errors.name && touched.name ? <div>{errors.name}</div> : null}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="relative my-3 text-gray-400 focus-within:text-gray-600 block"
                >
                  <FaEnvelope className="pointer-events-none w-6 h-6 transition-colors duration-100 absolute top-1/2 transform -translate-y-1/2 left-3" />
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@gmail.com"
                    className="form-input rounded-md border border-gray-900 py-3 px-4 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-14 focus:outline-none"
                  />
                </label>
                {errors.email && touched.email ? (
                  <div>{errors.email}</div>
                ) : null}
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="relative mt-3 text-gray-400 focus-within:text-gray-600 block"
                >
                  <FaPhone className="pointer-events-none w-6 h-6 transition-colors duration-100 absolute top-1/2 transform -translate-y-1/2 left-3" />
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="(99) 99999-9999"
                        id="phone"
                        placeholder="Telefone"
                        type="text"
                        className="form-input rounded-md border border-gray-900 py-2 px-3 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block pl-14 focus:outline-none"
                      />
                    )}
                  />
                </label>
                {errors.phone && touched.phone ? (
                  <div>{errors.phone}</div>
                ) : null}
              </div>
              <span className="w-full mt-5">
                <button
                  className="w-full lg:w-1/3 bg-gray-900 hover:bg-gray-800 text-white font-semibold py-4 transition-all rounded-full mx-auto"
                  type="submit"
                >
                  Enviar
                </button>
              </span>
            </Form>
          )}
        </Formik>
      </div>
      <div className="m-auto my-12"></div>
      <div className="hidden lg:max-w-xs lg:flex select-none">
        <StaticImage
          src="../images/contBoy.png"
          alt="Um menino com uma carta"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default Contact;
