import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Intro = () => {
  return (
    <section
      id="intro"
      className="flex flex-wrap flex-row-reverse w-full justify-center items-center lg:mb-36 px-10 xl:px-40 md:mt-28 mt-12 mb-12"
    >
      <div className="lg:w-1/2 w-full lg:ml-5">
        <h1 className="font-bold text-gray-900 mx-[5%] sm:mx-auto text-center text-3xl md:text-5xl lg:text-6xl lg:text-right">
          Introdução breve à história da Lisbom
        </h1>
        <p className=" text-gray-500 text-center text-base my-6 lg:text-2xl lg:text-right md:px-0 px-5">
          A Lisbom nasceu de um pequeno grupo de desenvolvedores alagoanos em
          2020 que conseguiram escalar para o mercado mundial utilizando de boas
          práticas e tecnologia de ponta, hoje contamos com dezenas de
          colaboradores especialistas no mercado digital e no desenvolvimento de
          sistemas.
        </p>
      </div>
      <div className="hidden lg:block m-auto my-12"></div>
      <div className="lg:max-w-xs lg:flex md:block w-1/2 mx-auto select-none">
        <StaticImage
          src="../images/intro-boy.svg"
          alt="intro boy"
          placeholder="blurred"
          quality={100}
        />
      </div>
    </section>
  );
};

export default Intro;
