import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Curiosities from "../components/Curiosities";
import FloatingBtn from "../components/FloatingBtn";
import Infos from "../components/Infos";
import Intro from "../components/Intro";
import SEO from "../components/SEO";
import Sections from "../components/Sections";
import Contact from "../partials/Contact";
import Footer from "../partials/Footer";
import Navbar from "../partials/Navbar";
import OurProjects from "../partials/OurProjects";
import Qualities from "../partials/Qualities";
import CEOWords from "../components/CEOWords";
import useMediaQuery from "../hooks/useMediaQuery";

const IndexPage = () => {
  //* hooks
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  //* render
  return (
    <div className="bg-white overflow-x-hidden relative ">
      <Sections>
        <Navbar />
        <main className="relative flex flex-wrap items-center justify-center !h-[100vh] mb-16">
          <div className="lg:w-1/2 lg:mt-0 mt-20 !z-30">
            <h1 className="w-full font-bold text-gray-900  text-center text-4xl md:text-5xl lg:text-6xl lg:text-left">
              A melhor empresa para desenvolver suas ideias no{" "}
              <strong className="text-[#FF5151]">Mercado Digital</strong>
            </h1>
            <p className=" text-gray-500 text-center text-base  my-6 lg:text-2xl lg:text-left">
              Transformando e escalando o mercado digital usando tecnologia,
              gestão ágil e experiência com vendas na internet.
            </p>
            <div className="flex w-full relative z-50 max-w-sm m-auto px-4 mb-6 lg:mb-1 lg:p-0 lg:m-0 items-center justify-evenly">
              <a
                href="#intro"
                className="inline-block text-center cursor-pointer bg-gray-900 w-full hover:bg-gray-800 text-white font-semibold py-4 transition-all rounded-full"
              >
                Sobre nós
              </a>
              <a
                href="#contact"
                className="inline-block text-center cursor-pointer w-full ml-4 z-50 border-gray-900 border-4 hover:bg-gray-900 hover:text-white text-gray-900 font-bold py-4 transition-all rounded-full"
              >
                Contato
              </a>
            </div>
          </div>
          <div className="flex w-full lg:w-1/2 right-0">
            {!isSmallScreen && (
              <StaticImage
                className="!absolute -top-28 -right-32 !w-[100%] xl:!w-[85%] !z-0 !hidden lg:!block select-none"
                src="../images/home-top-bg.png"
                alt="Dark-Bg"
                placeholder="blurred"
                quality={100}
              />
            )}
            {!isLargeScreen && (
              <StaticImage
                objectFit="fill"
                className="!absolute bottom-0 -left-10 md:-left-14 w-[130%] h-32 md:h-auto lg:!hidden !z-0 select-none"
                src="../images/projectsBot2.svg"
                alt="Dark-Bg"
                placeholder="blurred"
                quality={100}
              />
            )}
            <StaticImage
              className="lg:!absolute !relative !z-10 lg:top-20 lg:right-28 w-[75%] sm:w-1/2 lg:w-1/4 lg:mt-5 lg:mx-0 mx-auto"
              src="../images/menino_usando_notebook.svg"
              alt="top-Img"
              placeholder="blurred"
              quality={100}
            />
          </div>
        </main>
      </Sections>
      <Qualities />
      <CEOWords />
      <Intro />
      <Infos />
      <OurProjects />
      <Curiosities />
      <Contact />
      <Footer />
      <FloatingBtn
        phone="554891969134"
        message="Oi, estou pronto para fazer meu negócio decolar!"
      />
    </div>
  );
};

export const Head = () => <SEO />;

export default IndexPage;
