import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';

const FloatingBtn = ({ phone, message }) => (
    <a
        href={`https://api.whatsapp.com/send?1=pt_br&phone=${phone}${message ? `&text=${encodeURI(message)}` : ''
            }`}
        className="text-xl hover:border-4 border-white transition-all md:text-3xl text-white fixed bottom-5 sm:bottom-12 right-3 md:right-5 bg-green-400 flex items-center justify-center w-10 h-10 md:w-14 md:h-14 rounded-full z-[100]"
    >
        <FaWhatsapp />
    </a>
);


export default FloatingBtn