import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Curiosities = () => {
  return (
    <section className="flex flex-wrap flex-row-reverse w-full justify-center items-center lg:mb-36 px-10 xl:px-40 md:mt-28 mt-12 mb-12">
      <div className="lg:w-1/2 w-full lg:ml-5">
        <h1 className="font-bold text-gray-900  text-center text-3xl md:text-5xl lg:text-6xl lg:text-right">
          O Céu <strong>NÃO</strong> é o Limite
        </h1>
        <p className=" text-gray-500 text-center text-base my-6 lg:text-2xl lg:text-right">
          Uma empresa jovem e que usam a alta performance diariamente para
          escalar. Com a Lisbom seu negócio irá para o próximo nível e com
          certeza o 7 em 7 vai chegar!
        </p>
      </div>
      <div className="hidden lg:block m-auto my-12"></div>
      <div className="lg:max-w-xs lg:flex md:block w-1/2 mx-auto select-none">
        <StaticImage
          src="../images/curiosity-boy.svg"
          alt="curiosity boy"
          placeholder="blurred"
          quality={100}
        />
      </div>
    </section>
  );
};

export default Curiosities;
