import { StaticImage } from "gatsby-plugin-image";
import React from 'react';
import { BsEnvelopeFill, BsGithub, BsInstagram } from "react-icons/bs";

const Navbar = () => {
    return (
        <div className="w-full items-center justify-between lg:p-0 p-5 lg:mt-14 z-10 flex lg:relative absolute top-0 left-0 lg:bg-transparent bg-lisbomDark">
            <div className='flex'>
                <StaticImage
                    className='w-[70%]'
                    src="../images/GansoRed.svg"
                    alt="icon"
                    placeholder="blurred"
                    quality={100}
                />
            </div>
            <div className="items-center flex justify-center">
                <a href='http://instagram.com/lisbom.ofc' className="pt-1 ml-5 w-6 text-gray-500 hover:text-white transition">
                    <BsInstagram size={22}/>
                </a>
                <a href='mailto:contato@lisbom.com.br' className="pt-1 ml-5 w-6 text-gray-500 hover:text-white transition">
                    <BsEnvelopeFill size={25}/>
                </a>
                <a href='https://github.com/lisbom-dev' className="pt-1 ml-5 w-6 text-gray-500 hover:text-white transition">
                    <BsGithub size={24}/>
                </a>
            </div>
        </div>
    );
}

export default Navbar;