import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const CEOWords = () => {
  //* render
  return (
    <div className="relative lg:h-[50rem] flex flex-col justify-between gap-3 xl:px-40 px-10">
      <h2 className="text-3xl md:text-5xl font-bold">
        Uma palavrinha do nosso CEO
      </h2>
      <div className="z-20 w-full flex flex-col-reverse h-full lg:flex-row items-start">
        <div className="relative w-full lg:w-1/2 h-fit lg:h-full">
          <StaticImage
            className="relative z-10 lg:!absolute h-full md:w-[30.375rem] md:h-[36.9375rem] object-scale-down -mb-3 md:-mb-2 lg:bottom-2 select-none"
            src="../images/ceo/manuel-photo.png"
            alt="Manuel Antunes photo"
            placeholder="blurred"
            quality={100}
          />
          {/* {<div className="!absolute bottom-0 md:-bottom-2 md:-right-10 right-0 h-1/2 w-full bg-gradient-to-b from-transparent via-transparent to-white lg:hidden" />} */}
          <StaticImage
            objectFit="fill"
            className="!absolute lg:!hidden -bottom-3  -left-12 z-0 w-[130%] select-none"
            src="../images/projectsBot2.svg"
            alt="Dark-Bg"
            placeholder="blurred"
            quality={100}
          />
        </div>
        <div className="flex w-full lg:w-1/2 2xl:w-full mt-5 h-full items-start">
          <p className="text-base text-justify lg:text-xl lg:mt-16 2xl:mt-0 2xl:text-2xl font-normal text-[#909090] ">
            Para escalar um bom negócio é necessário fortalecer 3 grandes
            frentes: pessoas, processos e ferramentas. Todos os 3 são igualmente
            importantes, na Lisbom prezamos ter um time bem desenvolvido e
            poderoso dentro do mercado, uma esteira madura e validada para todos
            os nossos processos e produtos, e além, é claro, utilizar boa
            tecnologia, de fácil manutenção e que atenda o momento de nossos
            clientes.
            <br />
            <br />
            A Lisbom, muito mais que desenvolver o software - com o melhor setor
            de produto para entender os planos do cliente - quer transformar
            digitalmente ideias as moldando ao sucesso máximo.
            <br />
            <br />
            Como dizemos aqui: miramos na Lua e acertamos em Marte. 🚀
          </p>
        </div>
      </div>
      <div className="!absolute z-20 bottom-6 lg:bottom-[4.5rem] w-52 sm:w-auto right-10 xl:right-[21.43rem]">
        <p className="text-lg md:text-xl text-white font-medium">
          diretor executivo
        </p>
        <StaticImage
          className="mt-2"
          src="../images/manuel-type-logo-white.svg"
          alt="Diretor Executivo - Manuel Antunes"
          placeholder="blurred"
          quality={100}
        />
      </div>
      <StaticImage
        className="!absolute z-10 -left-5 md:left-8 bottom-36 select-none"
        src="../images/circle-purple.svg"
        quality={100}
        alt="purple circle"
      />
      <StaticImage
        className="!absolute z-10 right-2 lg:right-96 xl:right-32 bottom-1 xl:bottom-10 2xl:bottom-28 select-none"
        src="../images/circle-blue.svg"
        quality={100}
        alt="blue circle"
      />
      <div className="!absolute w-full bottom-0 left-0">
        <StaticImage
          className="z-0 md:-mb-2 !hidden sm:!hidden lg:!block select-none"
          src="../images/projectsTop.svg"
          alt="top curved png"
          placeholder="blurred"
          quality={100}
        />
        <div className="w-full h-[7.625rem] bg-[#151F28] !hidden sm:!hidden lg:!block" />
      </div>
    </div>
  );
};

export default CEOWords;
