import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Qualities = () => {
  return (
    <section>
      <h1 className="font-bold lg:mt-32 text-gray-900 text-center text-3xl md:text-5xl lg:text-left lg:w-5/6 xl:px-40 px-10">
        Em nossos projetos, prezamos sempre pelas seguintes qualidades:
      </h1>
      <div className="relative flex flex-col lg:flex-row gap-20 mt-20 w-full justify-center items-center lg:mb-36 px-10 my-8 md:px-48">
        <div className="lg:w-1/3 flex gap-x-5 items-center text-center h-96">
          <p className="text-8xl w-1/4 font-quicksand font-bold hidden md:block left-5 lg:bottom-28 text-lisbomNumber">
            1
          </p>
          <div className="flex flex-col items-center justify-center">
            <StaticImage
              className="h-48 xl:h-72 w-48 xl:w-72"
              src="../images/puzzle-1.svg"
              alt="puzzle"
              placeholder="blurred"
              quality={100}
            />
            <p className="md:text-left h-48 text-center text-gray-500 mt-5">
              <strong>Processos bem definidos -</strong> trabalhando com o
              auxílio do SCRUM e técnicas de gestão de projeto, garantindo uma
              entrega melhor de produto.
            </p>
          </div>
        </div>
        <div className="lg:w-1/3 flex gap-x-5 items-center text-center h-96">
          <p className="text-8xl w-1/4 font-quicksand font-bold hidden md:block left-5 lg:bottom-28 text-lisbomNumber">
            2
          </p>
          <div className="flex flex-col items-center justify-center">
            <StaticImage
              className="h-48 xl:h-72 w-48 xl:w-72"
              src="../images/rocket-1.svg"
              alt="puzzle"
              placeholder="blurred"
              quality={100}
            />
            <p className="md:text-left h-48 text-center text-gray-500 mt-5">
              <strong>Inovação -</strong> buscamos entregar um produto inovador,
              com tecnologias de alta performance e escalabilidade, sempre em
              busca de agregar ainda mais valor a seu produto.
            </p>
          </div>
        </div>
        <div className="lg:w-1/3 flex gap-x-5 items-center text-center h-96">
          <p className="text-8xl w-1/4 font-quicksand font-bold hidden md:block left-5 lg:bottom-28 text-lisbomNumber">
            3
          </p>
          <div className="flex flex-col items-center justify-center">
            <StaticImage
              className="h-48 xl:h-72 w-48 xl:w-72"
              src="../images/conference-1.svg"
              alt="puzzle"
              placeholder="blurred"
              quality={100}
            />
            <p className="md:text-left h-48 text-center text-gray-500 mt-5">
              <strong>Melhores desenvolvedores do mercado -</strong> ousamos
              dizer que temos em nosso leque de colaboradores os melhores do
              mercado Latino. Experientes em criar e lançar soluções digitais.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
